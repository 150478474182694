<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h3 class="pageHeadline" t-data="page-headline">
          Customers
        </h3>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="6" t-data="search-panel">
            <search
              :search="search"
              label="Search by Name, Mobile No. or Email"
              @input="requestSearchUserList"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="flex-grow-0" t-data="filter-panel">
            <filter-dropdown @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
      <v-data-table
        :headers="headers"
        :items="customerList"
        :search="search"
        :no-results-text="noResultText()"
        :no-data-text="noResultText()"
        :options.sync="options"
        :server-items-length="total"
        :loading="loadingList"
        :footer-props="{'items-per-page-options': perpage}"
        class="elevation-1 custom-table customer-table"
        @click:row="handlerClick"
        fixed-header
        style="width: 100%"
        :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
        <template v-slot:item.create_date="{item}">{{
          convertDate(item.create_date)
        }}</template>
        <template v-slot:item.email="{item}">
          <v-row align="center">
            <div
              v-show="!item.email_verify"
              style="width: 16px; height: 16px; margin-right: 5px;"
            ></div>
            <v-img
              aspect-ratio="1"
              v-show="item.email_verify"
              max-height="16"
              max-width="16"
              src="/images/email-check-icon.png"
              style="margin-right: 5px;"
            ></v-img>
            {{ item.email }}
          </v-row>
        </template>
        <template v-slot:item.user_member_ship="{item}">
          <div class="d-flex justify-center">
            <v-img
              aspect-ratio="1"
              v-show="item.user_member_ship !== null ? true : false"
              max-height="24"
              max-width="24"
              src="/images/check-icon.png"
            ></v-img>
          </div>
        </template>
        <template v-slot:item.is_delete="{item}">
          <customer-status-chip :status="item.is_delete"></customer-status-chip>
        </template>
      </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import CustomerStatusChip from '@/components/customer/CustomerStatusChip'
import FilterDropdown from '@/views/customer/FilterDropdown'
import Search from '@/components/Search'
import {convertDate, searchHandler} from '@/helper/helper'

export default {
  name: 'home',
  components: {
    FilterDropdown,
    Search,
    CustomerStatusChip
  },
  async created() {
    await this.setListOrgInfo()
    await this.fetch()
  },
  beforeRouteLeave(to, from, next) {
    this.resetList()
    next()
  },
  computed: {
    ...mapState('customer', ['customerList', 'loadingList', 'total'])
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    handlerClick(value) {
      this.$router.push({name: 'user-detail', params: {uid: value.uid}})
    },
    convertDate(date) {
      return convertDate(date)
    },
    noResultText() {
      return this.search === ''
        ? `No Customer available`
        : `Search Not Found "${this.search}"`
    },
    requestSearchUserList: async function(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    ...mapActions('customer', [
      'fetch',
      'resetList',
      'setFieldSort',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
  },
  data() {
    return {
      heightOptions: {
        header: 312,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      search: '',
      itemsPerPage: 25,
      sortBy: '',
      sortDesc: false,
      options: {},
      perpage: [25, 50, 100],
      headers: [
        {
          text: 'Firstname',
          value: 'first_name',
          class: 'font-weight-bold',
          align: 'start'
        },
        {text: 'Lastname', value: 'last_name'},
        {text: 'Mobile No.', value: 'phone_number'},
        {text: 'Email', value: 'email', class: 'pl-6'},
        {text: 'Registered On', value: 'create_date'},
        {
          text: 'Membership',
          value: 'user_member_ship',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Status',
          value: 'is_delete',
          sortable: true,
          align: 'center'
        }
      ],
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/'
        },
        {
          text: 'Customers',
          disabled: true,
          link: true,
          to: '/'
        }
      ],
      dropdown_membership: [
        {
          text: 'All Membership'
        },
        {
          text: 'Member'
        },
        {
          text: 'Non Member'
        }
      ],
      dropdown_status: [
        {text: 'All Status'},
        {text: 'Active'},
        {text: 'Inactive'}
      ]
    }
  }
}
</script>

<style lang="scss">
.customer-table tr {
  cursor: pointer;
}
</style>