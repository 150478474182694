var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h3',{staticClass:"pageHeadline",attrs:{"t-data":"page-headline"}},[_vm._v(" Customers ")])],1)],1),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","t-data":"search-panel"}},[_c('search',{attrs:{"search":_vm.search,"label":"Search by Name, Mobile No. or Email"},on:{"input":_vm.requestSearchUserList,"clear":function($event){return _vm.clearSearch()}}})],1),_c('v-spacer'),_c('v-col',{staticClass:"flex-grow-0",attrs:{"t-data":"filter-panel"}},[_c('filter-dropdown',{on:{"apply":_vm.handleApplyFilter}})],1)],1)],1),_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 custom-table customer-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.customerList,"search":_vm.search,"no-results-text":_vm.noResultText(),"no-data-text":_vm.noResultText(),"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingList,"footer-props":{'items-per-page-options': _vm.perpage},"fixed-header":"","height":_vm.windowSize.y - _vm.heightOptions.header - _vm.heightOptions.tableFooter},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handlerClick},scopedSlots:_vm._u([{key:"item.create_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.convertDate(item.create_date)))]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.email_verify),expression:"!item.email_verify"}],staticStyle:{"width":"16px","height":"16px","margin-right":"5px"}}),_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(item.email_verify),expression:"item.email_verify"}],staticStyle:{"margin-right":"5px"},attrs:{"aspect-ratio":"1","max-height":"16","max-width":"16","src":"/images/email-check-icon.png"}}),_vm._v(" "+_vm._s(item.email)+" ")],1)]}},{key:"item.user_member_ship",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(item.user_member_ship !== null ? true : false),expression:"item.user_member_ship !== null ? true : false"}],attrs:{"aspect-ratio":"1","max-height":"24","max-width":"24","src":"/images/check-icon.png"}})],1)]}},{key:"item.is_delete",fn:function(ref){
var item = ref.item;
return [_c('customer-status-chip',{attrs:{"status":item.is_delete}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }