<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-2 mr-2" v-show="hasAllCustomerPerm()">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Binding</p>
          <v-checkbox
            id="filter-connected"
            v-model="bindingSelected"
            hide-details
            value="connected"
            @change="changedBindingValue"
            label="Connected"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
          <v-checkbox
            id="filter-unconnected"
            v-model="bindingSelected"
            hide-details
            @change="changedBindingValue"
            value="unconnected"
            label="Unconnected"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
        </div>
      </v-list-item>
      <v-list-item v-show="!showHospitalFilter() && hasAllCustomerPerm()">
        <v-divider class="divider-bg"></v-divider>
      </v-list-item>
      <v-list-item class="ml-5 mr-5 fixWidth d-block my-6" v-if="showHospitalFilter()">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>
      <v-list-item class="ml-2 mr-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Device OS</p>
          <v-checkbox
            id="filter-device-ios"
            v-model="deviceOSSelected"
            hide-details
            value="ios"
            label="iOS"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
          <v-checkbox
            id="filter-device-android"
            v-model="deviceOSSelected"
            hide-details
            value="android"
            label="Android"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
        </div>
      </v-list-item>
      <v-list-item>
        <v-divider class="divider-bg"></v-divider>
      </v-list-item>
      <v-list-item class="ml-2 mr-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Membership</p>
          <v-checkbox
            id="filter-membership"
            v-model="memberSelected"
            hide-details
            value="0"
            label="Membership"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
          <v-checkbox
            id="filter-non-membership"
            v-model="memberSelected"
            hide-details
            value="1"
            label="Non-Membership"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
        </div>
      </v-list-item>
      <v-list-item>
        <v-divider class="divider-bg"></v-divider>
      </v-list-item>
      <v-list-item class="mb-6 ml-2 mr-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Status</p>
          <v-checkbox
            v-model="isDeleteSelected"
            hide-details
            value="0"
            label="Active"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
          <v-checkbox
            v-model="isDeleteSelected"
            hide-details
            value="1"
            label="Inactive"
            class="mt-0 filterContent body-regular"
          ></v-checkbox>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import FilterButton from '@/components/FilterButton'
import {convertSelectedData, hasPermision} from '@/helper/helper'
import MultipleSelected from '@/components/MultipleSelected'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    bindingSelected: null,
    deviceOSSelected: null,
    memberSelected: null,
    isDeleteSelected: null,
    countFiltered: 0,
    isChanged: false,
    appliedBinding: null,
    appliedBranches: [],
    appliedDeviceOS: null,
    appliedMember: null,
    appliedIsDelete: null,
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected,
  },
  computed: {
    isClearFilter() {
      const changedBinding = !_.isEqual(this.bindingSelected, null)
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      const changedDeviceOS = !_.isEqual(this.deviceOSSelected, null)
      const changedMember = !_.isEqual(this.memberSelected, null)
      const changedIsDelete = !_.isEqual(this.isDeleteSelected, null)
      if (this.hasOneOrg) return changedBinding || changedDeviceOS || changedMember || changedIsDelete
      return changedBranch || changedBinding || changedDeviceOS || changedMember || changedIsDelete
    },
    isChangeFilter() {
      const changedBinding = !_.isEqual(this.bindingSelected, this.appliedBinding)
      const changedBranch = !_.isEqual(this.orgList.selected, this.appliedBranches)
      const changedDeviceOS = !_.isEqual(this.deviceOSSelected, this.appliedDeviceOS)
      const changedMember = !_.isEqual(this.memberSelected, this.appliedMember)
      const changedIsDelete = !_.isEqual(this.isDeleteSelected, this.appliedIsDelete)
      if (this.hasOneOrg) return changedBinding || changedDeviceOS || changedMember || changedIsDelete
      return changedBranch || changedBinding || changedDeviceOS || changedMember || changedIsDelete
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    ...mapState('customer', ['orgList'])
  },
  methods: {
    changedBindingValue() {
      this.orgList.selected = []
    },
    hasAllCustomerPerm() {
      return !!hasPermision('CanViewAllCustomer');
    },
    showHospitalFilter() {
      return this.bindingSelected === 'connected';
    },
    sumFiltered() {
      const sumBinding = this.bindingSelected !== null ? 1 : 0
      const sumBranch = this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumDeviceOS = this.deviceOSSelected !== null ? 1 : 0
      const sumMember = this.memberSelected !== null ? 1 : 0
      const sumIsDelete = this.isDeleteSelected !== null ? 1 : 0
      return sumBinding + sumBranch + sumDeviceOS + sumMember + sumIsDelete
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBinding = this.bindingSelected
      this.appliedBranches = this.orgList.selected
      this.appliedDeviceOS = this.deviceOSSelected
      this.appliedMember = this.memberSelected
      this.appliedIsDelete = this.isDeleteSelected
      this.setFilterBinding(this.bindingSelected)
      this.setFilterDeviceOS(this.deviceOSSelected)
      this.setFilterMember(this.memberSelected)
      this.setFilterIsDelete(this.isDeleteSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.bindingSelected = null
      this.deviceOSSelected = null
      this.memberSelected = null
      this.isDeleteSelected = null
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }

      // wait for multiple select render
      this.$nextTick(() => {
        this.bindingSelected = this.appliedBinding
        this.deviceOSSelected = this.appliedDeviceOS
        this.memberSelected = this.appliedMember
        this.isDeleteSelected = this.appliedIsDelete

        this.setFilterBinding(this.bindingSelected)
        this.setFilterDeviceOS(this.deviceOSSelected)
        this.setFilterMember(this.memberSelected)
        this.setFilterIsDelete(this.setFilterIsDelete)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('customer', [
      'fetch',
      'setFilterBinding',
      'setFilterDeviceOS',
      'setFilterMember',
      'setFilterIsDelete'
    ])
  }
}
</script>
